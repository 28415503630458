import { storeToRefs } from 'pinia'
import { useAppStore } from '~/store/app'
import { IContact } from '~/types/seo'
import { SPORTS_URLS, PAGE_URLS, ACCOUNT_URLS, LOBBY_GAME_URLS, BASE } from '~/config/page-url'
import { PAGE_PARAMS } from '~/constants/common'
import {useAccountStore} from "~/store/account";
const PATH_IMAGE = '/assets/images/components/desktop/footer/'
const accountStore = useAccountStore()
export const FOOTER_NAVIGATION = [
  {
    title: 'Khuyến mãi',
    link: PAGE_URLS.PROMOTION
  },
  {
    title: 'Giới thiệu',
    link: PAGE_URLS.ABOUT_US
  },
  {
    title: 'Điều khoản điều kiện',
    link: PAGE_URLS.POLICY
  },
  {
    title: 'Hướng dẫn nạp rút',
    link: PAGE_URLS.DEPOSIT_GUIDE
  },
  {
    title: 'Hướng dẫn đăng ký',
    link: PAGE_URLS.REGISTRATION_GUIDE
  },
  {
    title: 'tip bóng đá ',
    link: PAGE_URLS.SPORT_TIP
  },
  {
    title: 'Soi kèo',
    link: PAGE_URLS.BETTING_GUIDE
  }
]

export const FOOTER_METHOD = () => {
  const dataDepositMomo = accountStore.momos
  const dataDepositViettelPays = accountStore.viettelPays
  return  [
    {
      title: 'CodePay',
      image: `${PATH_IMAGE}codepay.svg`,
      link: ACCOUNT_URLS.DEPOSIT_DA
    },
    {
      title: 'P2P',
      image: `${PATH_IMAGE}p2p.svg`,
      link: ACCOUNT_URLS.P2P
    },
    {
      title: 'Tiền ảo',
      image: `${PATH_IMAGE}crypto.svg`,
      link: ACCOUNT_URLS.DEPOSIT_CRYPTO
    },
    {
      title: 'Momo',
      image: `${PATH_IMAGE}momo.svg`,
      link: dataDepositMomo.length ? ACCOUNT_URLS.DEPOSIT_MOMO : ACCOUNT_URLS.DEPOSIT_VIETTEL_PAY
    },
    {
      title: 'Viettel Money',
      image: `${PATH_IMAGE}viettelpay.svg`,
      link: dataDepositViettelPays.length ? ACCOUNT_URLS.DEPOSIT_VIETTEL_PAY : ACCOUNT_URLS.DEPOSIT_MOMO
    },
    {
      title: 'Thẻ cào',
      image: `${PATH_IMAGE}card.svg`,
      link: ACCOUNT_URLS.DEPOSIT_CARD
    }
  ]
}

export const FOOTER__PROVIDERS = [
  `${PATH_IMAGE}provider/b52.webp`,
  `${PATH_IMAGE}provider/rik-vip.webp`,
  `${PATH_IMAGE}provider/iwin.webp`,
  `${PATH_IMAGE}provider/go88.webp`,
  `${PATH_IMAGE}provider/evolution-gaming.webp`,
  `${PATH_IMAGE}provider/vivo-gaming.webp`,
  `${PATH_IMAGE}provider/ezugi.webp`,
  `${PATH_IMAGE}provider/pragmatic-play.webp`,
  `${PATH_IMAGE}provider/dream-gaming.webp`,
  `${PATH_IMAGE}provider/hacksaw.webp`
]

export const getFooterAboutUs = () => {
  const { $config } = useNuxtApp()

  return {
    INTRODUCE_LIST: [
      {
        name: 'Khuyến mãi',
        icon: PATH_IMAGE + 'icon-responsibility.svg',
        url: PAGE_URLS.PROMOTION
      },
      {
        name: 'Hướng dẫn',
        icon: PATH_IMAGE + 'icon-privacy.svg',
        url: PAGE_URLS.REGISTRATION_GUIDE
      },
      {
        name: 'Giới thiệu',
        icon: PATH_IMAGE + 'icon-introduce.svg',
        url: PAGE_URLS.ABOUT_US
      },
      {
        name: 'Tin tức',
        icon: PATH_IMAGE + 'icon-policy.svg',
        url: PAGE_URLS.NEWS
      },
      {
        name: 'Điều khoản',
        icon: PATH_IMAGE + 'icon-desc.svg',
        url: PAGE_URLS.POLICY
      },
      {
        name: 'Soi kèo bóng rổ',
        icon: PATH_IMAGE + 'icon-news.svg',
        url: PAGE_URLS.NEWS_CATEGORY_BASKETBALL,
        type: PAGE_URLS.BETTING_GUIDE
      }
    ]
  }
}
export const getFooterContact = () => {
  const { $config, $pinia } = useNuxtApp()
  const store = useAppStore($pinia)
  const { contact } = storeToRefs(store)
  const result = contact?.value?.reduce((acc, item: IContact) => {
    acc[item.name.toLowerCase()] = { url: item.url }
    return acc
  }, {} as Record<string, { url: string }>)

  return {
    SUPPORT_MENU: [
      {
        name: 'Live chat 24/7',
        url: result?.livechat?.url,
        activeUrl: [],
        isLiveChat: true,
        icon: PATH_IMAGE + 'icon-livechat.webp',
        iconMB: PATH_IMAGE + 'icon-livechat-mb.svg'
      },
      {
        name: `Telegram ${$config.public.BRANCH_NAME}`,
        url: result?.telegram?.url,
        activeUrl: [],
        icon: PATH_IMAGE + 'icon-telegram.webp',
        iconMB: PATH_IMAGE + 'icon-telegram-mb.svg'
      }
      // {
      //   name: 'Cộng đồng',
      //   url: result?.cskh?.url,
      //   activeUrl: [],
      //   icon: PATH_IMAGE + 'icon-community.webp',
      //   iconMB: PATH_IMAGE + 'icon-community-mb.svg'
      // },
      // {
      //   name: 'Hotline',
      //   url: `tel:${result?.hotline?.url}`,
      //   activeUrl: [],
      //   icon: PATH_IMAGE + 'icon-phone.webp',
      //   iconMB: PATH_IMAGE + 'icon-phone-mb.svg'
      // },
      // {
      //   name: `Fanpage ${$config.public.BRANCH_NAME}`,
      //   url: result?.fanpage?.url,
      //   activeUrl: [],
      //   icon: PATH_IMAGE + 'icon-fb.webp',
      //   iconMB: PATH_IMAGE + 'icon-fb-mb.svg',
      //   className: 'icon-fanpage'
      // }
    ]
  }
}

export const getFooterContent = () => {
  const { $config } = useNuxtApp()

  return {
    SLOGAN_DESCRIPTION: `${$config.public.BRANCH_NAME} - Nhà cái đẳng cấp Châu Âu. ${$config.public.BRANCH_NAME} đem đến cho khách hàng dịch vụ cá cược trực tuyến hàng đầu với hàng trăm sản phẩm cược hấp dẫn như: Thể Thao, Thể Thao Ảo, Casino, Number Games, Keno, Sòng Bài Số...`,
    VIEW_MORE: 'xem thêm',
    COPY_RIGHT: `Copyright © 2024 Powered By ${$config.public.BRANCH_NAME} All Rights Reserved.`
  }
}

export const getInfoBrand = () => {
  const { $config } = useNuxtApp()

  return {
    title: `${$config.public.BRANCH_NAME} - Nhà cái cá cược uy tín nhất Châu Á, nạp rút siêu tốc.`,
    description: `Nhà cái ${$config.public.BRANCH_NAME} là trang cược bóng đá nhanh nhất trên thị trường hiện nay, hoàn trả mỗi ngày cao nhất 1.8%. ${$config.public.BRANCH_NAME} cung cấp hai loại bảng kèo K-Sports; C-Sports và tỷ lệ cược liên tục cập nhật từng giây. Lịch thi đấu mỗi ngày, phân tích trận đấu, soi kèo banh Ngoại Hạnh Anh, Cúp C1, Serie A, Laliga, Bundesliga, Ligue 1,... và tất cả các giải lớn nhỏ khác. Trải nghiệm mượt mà, mọi lúc mọi nơi.`
  }
}
